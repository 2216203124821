.dataframeLoadingContainer {
    background-color: black;
    color: red;
    padding: 0.5rem;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dataframeLoadingOverlay {
    background-color: black;
}

.dataframeLoadingOverlay .ant-spin-text {
    text-shadow: none !important;
    font-style: italic;
    text-align: center !important;
    color: #ffffff !important;
}

.dataframeLoadingOverlay .ant-spin-dot {
    text-align: center !important;
}

.dataframeLoadingOverlay .ant-spin-dot-item {
    background-color: #71acff !important;
}

.dataframeLoadingOverlay .ant-spin-blur {
    opacity: 0.3 !important;
}

.successDfMessage {
    color: green;
}
