.sidebarContainer {
    height: calc(100vh - 6px);
    width: 200px;
    background-color: var(--sidebar-bg-color);
    text-align: center;
    padding: 1rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
}

.sidebarContainer.sidebarCollapsed {
    transition: none;
    width: 0px;
    padding: 0;
    border: none;
}

.logoutContainer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: rgb(229, 240, 255);
    padding: 0.25rem;
    border-radius: 0.25rem;
    margin-top: auto;
}

.roadmapHeader {
    margin: 1rem;
}

.step {
    min-height: 100px;
}

.ant-steps-item-icon {
    margin-inline-end: 0.5rem;
}

.ant-steps-item-content {
    padding-left: 0.5rem;
}

.ant-steps-item-title {
    line-height: 20px !important;
    margin-bottom: 0.25rem !important;
    padding-inline-end: 0.5rem !important;
}

.ant-steps-item-active .ant-steps-item-content {
    outline: 2px solid #1677ff;
    border-radius: 5px;
    transition: padding-left 0.2s ease-in-out;
    background: white;
}

.ant-steps-item-active .ant-steps-item-title {
    font-weight: 600;
}

.ant-steps-item-active .unfinishedTaskIcon {
    border-color: #1677ff;
    background-color: white;
}

.taskProgressContainer {
    margin-bottom: 1rem;
}

.unfinishedTaskIcon {
    width: 22px;
    height: 22px;
    border: 2px solid #929292;
    border-radius: 50%;
    display: inline-block;
}

.stepDescriptionText {
    white-space: pre-wrap;
}

/* Media queries */

@media (min-width: 1200px) {
    .sidebarContainer {
        width: 250px;
    }
}

@media (min-width: 1440px) {
    .sidebarContainer {
        width: 300px;
    }
}

@media (min-width: 1900px) {
    /* .sidebarContainer {
        width: 350px;
    } */
}
