.rightSideContainer {
    height: 100vh;
    flex: 0 0 30%;
    max-width: 600px;
    display: flex;
    flex-direction: column !important;
    border: 3px solid black;
}

.rightSideContainer.siderCollapsed {
    flex: 0 0 110px;
}

.rightSiderContainer {
    flex: 1 !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 110px !important;
    max-width: none !important;
    width: 100% !important;
    background-color: black !important;
}

.rightSiderContainer.siderCollapsed {
    background-color: var(--sidebar-bg-color) !important;
}

.sideBarPlaceHolderText {
    color: black;
    text-align: center;
}

.rightSiderContainer > .ant-layout-sider-children {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;
}

.rightSiderContainer.siderCollapsed > .ant-layout-sider-children {
    width: 110px;
}

.rightSiderCollapseBtn {
    min-width: 110px;
    position: absolute;
}
.rightSiderContainer:not(.siderCollapsed) .rightSiderCollapseBtn {
    border-radius: 0 !important;
}

.rightSiderContainer .rightSiderCollapseBtn.topCollapseBtn {
    top: 0;
    border-bottom-right-radius: 0.5rem !important;
}

.rightSideContainer .rightSiderCollapseBtn.bottomCollapseBtn {
    bottom: 0;
    border-top-right-radius: 0.5rem !important;
}

.rightColumnHalfContainer {
    /* margin-right: 1rem; */
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--sidebar-bg-color);
    max-height: 50%;
    overflow: auto;
}

.topHalf {
}

.bottomHalf {
    /* margin-bottom: 1rem; */
    /* margin-top: 1rem; */
}

.codePreviewOuterContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
}

.consoleViewerOuterContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    padding: 0.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.rightColumnHalfContainer h2 {
    text-align: center;
    flex: 0 0 auto;
}

.codePreviewInnerContainer {
    flex: 1;
    overflow: auto;
    white-space: pre-line;
    cursor: text;
    font-size: 0.75rem;
}

.codePreviewTitle {
    align-self: flex-end;
    margin-right: 1rem;
}

/* media query min-width 1200px */
@media (min-width: 1200px) {
    .codePreviewTitle {
        margin-right: 2rem;
    }
}

/* media query min-width 1440px */
@media (min-width: 1440px) {
    .codePreviewTitle {
        align-self: center;
        margin: 0;
    }

    .codePreviewInnerContainer {
        font-size: 0.9rem;
    }
}

/* media query min-width 1920px */
@media (min-width: 1920px) {
    .codePreviewInnerContainer {
        font-size: 1rem;
    }
}
