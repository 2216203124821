.mainContainer.problemDescriptionContainer {
    justify-content: start;
}

.backToProblemsButton {
    align-self: flex-start;
}

.titleContainer {
    width: 90%;
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    font-size: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
}

.descriptionContainer > img {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
    max-width: 800px;
    align-self: center;
}

.descriptionContainer > p {
    text-align: start;
    margin-bottom: 1rem;
}

.startButton {
    background-color: #52c41a;
    border-color: #52c41a;
    color: white;
    font-weight: 600;
}

.startButton:hover,
.startButton:focus {
    background-color: #43a047 !important;
    border-color: #43a047 !important;
    color: white !important;
    box-shadow: 0 0 10px 1px rgba(59, 255, 33, 0.674) !important;
}

.startButton:active {
    background-color: #388e3c;
    border-color: #388e3c;
}
