.ant-list-header {
    text-align: center;
}

.ant-list-header h4 {
    font-weight: bold;
}

.moduleSelectorContainer {
    width: 100%;
}

.moduleSelectorContainer > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
}

.moduleSelectorContainer > span > div {
    width: 100%;
}

.moduleSelectorContainer > span > .ant-divider {
    width: 100%;
    border-block-start: 1px solid black;
    margin: 0;
}

.listContainer {
    background-color: black;
    color: white;
    padding: 0 0.25rem 1rem 0.25rem;
    overflow: hidden auto;
    min-height: 200px;
    max-height: 200px;
}

.listItem {
    background-color: rgb(124, 124, 124);
    text-align: center;
    border: 2px solid black;
}

.listItem:last-child:not(:only-child) {
    border-radius: 0 0 8px 8px;
}

.listItem .removeLibraryIcon {
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.3s ease-in-out;
}

.listItem:hover .removeLibraryIcon {
    opacity: 1;
}

.removeLibraryIcon:hover {
    transform: scale(1.2) rotate(90deg);
}

.removeLibraryIcon:focus {
    opacity: 1;
}

.toolTip::after {
    content: attr(data-tooltip);
    position: fixed;
    visibility: hidden;
    opacity: 0;
    width: max-content;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    transform: translateX(10px) translateY(-5px);
    transition: visibility 0s, opacity 0.2s linear;
    font-style: italic;
    font-size: 0.75rem;
}

.toolTip:hover::after {
    visibility: visible;
    opacity: 0.75;
}
