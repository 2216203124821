.errorDisplayContainer {
    background-color: black;
    color: red;
    padding: 0.5rem;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.successVisualizer {
    color: green;
}
