.console {
    background-color: black;
    color: limegreen;
    font-family: "Courier New", Courier, monospace;
    padding: 10px;
    padding-bottom: 1.25rem;
    border-radius: 4px;
    width: 100%;
    /* height: 300px; */
    overflow: auto;
    white-space: pre;
    cursor: text;
    user-select: text !important;
}

/* .console.globalConsole {
    height: 100%;
} */

.consoleViewerOuterContainer > .consoleOutputContainer {
    display: flex;
    flex: 1;
    overflow: auto;
    cursor: text;
}

.consoleLoadingOverlay {
    background-color: black;
}

.consoleViewerOuterContainer .consoleLoadingOverlay {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.consoleOutputContainer {
    display: flex;
    flex-direction: column;
}

.consoleOutputContainer .ant-spin-text {
    text-shadow: none !important;
    font-style: italic;
    left: 6% !important;
    top: 80% !important;
    text-align: start !important;
    color: #c7c7c7 !important;
    width: fit-content !important;
}

.consoleOutputContainer .ant-spin-dot {
    left: 15% !important;
    top: 80% !important;
    text-align: start !important;
}

.consoleOutputContainer .ant-spin-dot-item {
    background-color: #71acff !important;
    opacity: 0.1 !important;
}

.consoleOutputContainer .ant-spin-blur {
    opacity: 0.3 !important;
}

.consoleOutputContainer .ant-spin-container {
    flex: 1;
}

.console pre {
    white-space: pre-wrap;
}
