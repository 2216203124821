.moduleViewerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    /* text-align: center; */
    position: relative;
}

.moduleViewerContent * {
    transition: none !important;
}

.moduleViewerContent .resizeBar {
    width: 100%;
}

.moduleViewerContent .ant-divider {
    margin: 1rem 0;
}

.moduleViewerTitle {
    text-align: center;
}

.moduleViewerForm {
    border: 2px solid black;
    padding: 1rem;
    border-radius: 0.5rem;
    width: fit-content;
    background-color: white;
    width: 100%;
    max-width: 550px;
}

.searchBarAndClearButtonContainer {
    flex: 1;
    max-width: 430px;
}

.sortByContainer {
    display: flex;
    gap: 1rem;
}

.moduleViewerHeader {
    position: sticky;
    padding: 1rem;
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.moduleViewerHeader.singleModuleHeader {
    justify-content: start !important;
    gap: 1rem !important;
}

.closeModuleViewerButton,
.moduleViewerHeaderSpacer {
    width: 160px;
    background-color: red;
    color: white;
    font-weight: 600;
}

.closeModuleViewerButton:hover {
    color: black !important;
}

.moduleViewerHeaderSpacer {
    display: none;
}

.moduleSearchAndResultsContainer {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #525252;
}

.moduleSearchResultsContainer {
    width: 100%;
    max-width: 550px;
}

.moduleListItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    border: 1px solid grey;
    background-color: black;
}

.moduleListItem:hover,
.moduleListItem:hover *:not(button, button *, .moduleStatusContainer) {
    background-color: #252525;
    /* color: black; */
}

.moduleListItemTop {
    width: 100%;
    display: flex !important;
    gap: 1rem;
}

.moduleDetails {
    min-width: 90px;
    flex: 0 0 auto;
    gap: 1rem;
}

.moduleListItemTitle {
    text-align: start;
}

.moduleTitleAndDescription {
    flex: 1;
    width: calc(100% - 90px - 1rem);
    display: flex;
    flex-direction: column;
    align-items: start;
}

.moduleOverview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.individualModuleContainer {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    background: #525252;
}

.moduleViewerModuleContent {
    max-width: 700px;
    width: 90%;
    background-color: white;
    padding: 1rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.moduleTextContent {
    /* white-space: pre-wrap; */
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.individualModuleHeader {
    border-bottom: 1px solid grey;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
}

.noResultsContainer {
    text-align: center;
    margin-top: 1rem;
}

.moduleTextContent pre {
    overflow: auto;
}

.moduleTextContent h6 {
    font-size: 1rem;
}

.moduleTextContent ul,
.moduleTextContent pre {
    margin: 0;
}

.moduleHeaderButtonContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.backToTopButton {
    display: block;
    position: absolute;
    z-index: 1000;
    top: 5.25rem;
    right: 22px;
}

.moduleStatusContainer {
    background-color: grey;
    color: black;
    border-radius: 0.25rem;
    padding: 0 0.25rem;
}

.moduleStatusContainer.moduleInProgress {
    background-color: #f0ad4e;
    color: black;
}

.moduleStatusContainer.moduleComplete {
    background-color: #007600;
    color: white;
}

.markModuleCompleteButton {
    margin: 0 auto;
    width: fit-content;
}

.markModuleCompleteButton.completeModule {
    background-color: #009200;
    color: white;
}

.moduleSearchRadioGroup {
    margin-left: 1rem;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1400px) {
    .moduleViewerHeaderSpacer {
        display: inherit;
    }

    .moduleViewerHeader {
        justify-content: space-between;
    }
}
