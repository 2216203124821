.mainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #dadada;
    width: 70%;
    height: 90vh;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    text-align: center;
    overflow: auto;
    gap: 0.5rem;
}

.ant-drawer-body .mainContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto !important;
    justify-content: start !important;
    gap: 2rem;
}

.titleContainer {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
}

.ant-drawer-body .titleContainer {
    padding: 1rem;
}

.problemListContainer > .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
}

.problemListContainer {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.problemCard {
    width: 100%;
    font-size: 1.25rem;
}

.problemCard:hover,
.problemCard:focus {
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.25), 0 3px 6px 0 rgba(0, 0, 0, 0.2),
        0 5px 12px 4px rgba(0, 0, 0, 0.15);
    outline: 3px solid #6f67be;
}

.modalButtonContainer {
    display: flex;
    justify-content: space-evenly;
}

.modalParagraphText {
    text-align: center;
}

.customModalBody {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.reviewDescriptionOrResumeProblemModal .ant-modal-title {
    font-size: 1.25rem;
}

.problemSelectionLogoutBtn {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.problemSubmittedDateContainer {
    position: relative;
}

.problemSubmittedDateContainer > span {
    position: absolute;
    font-size: 0.75rem;
    font-style: italic;
}

.submittedDateText {
    right: 0;
}

.tasksCompletedText {
    left: 0;
}

.attemptNumberText {
    text-align: end;
    font-size: 0.75rem;
    font-style: italic;
}

/* Media query 1440px and up */
@media (min-width: 1440px) {
    .mainContainer {
        width: 60%;
        height: 90vh;
        padding: 20px;
        text-align: center;
    }

    .problemListContainer {
        max-width: 60%;
    }
}

/* Media query 1920px and up */
@media (min-width: 1920px) {
    .mainContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background-color: #dadada;
        width: 50%;
        height: 90vh;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        text-align: center;
    }

    .titleContainer {
        background-color: white;
        padding: 2rem;
        border-radius: 0.5rem;
    }

    .problemListContainer {
        max-width: 60%;
    }
}
