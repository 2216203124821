#root {
  background-color: #202020;
  width: 100%;
  overflow: hidden;
}

:root {
  --sidebar-bg-color: #c9e4ff;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.pageLoadingBackground {
  background-color: #fff;
  border-radius: .5rem;
  padding: 1rem;
}

.pageLoadingBackground h1 {
  font-style: italic;
  font-weight: bolder;
}

.loginContainer {
  background: #fff;
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 700px;
  height: 400px;
  display: flex;
}

.loginContainer > form {
  width: 70%;
  max-width: 600px;
}

.welcomeUsernameMsg {
  z-index: 2;
  color: #fff;
  text-align: center;
  background: #001529;
  border-bottom: 1px solid #d9d9d9;
  width: 110px;
  padding: .5rem 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.welcomeUsernameMsg.adjustUserWelcomeMsg {
  margin-top: 2.5rem;
  transition: all .25s ease-in-out;
}

.usernameBold {
  font-weight: 600;
}

#usernameText {
  text-wrap: nowrap;
  width: 110px;
  display: inline-block;
  overflow: hidden;
}

.BlockEditorContainer {
  width: 100%;
  display: flex;
}

.mainCenterColumn {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.editorContainer {
  cursor: grab;
  -webkit-user-select: none;
  user-select: none;
  flex: 1;
  position: relative;
}

.editorContainer:active {
  cursor: grabbing !important;
}

.ant-form-item-control-input-content {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.selectDropDown {
  width: 100%;
}

.ant-select-dropdown {
  width: fit-content;
}

.ant-upload-list-item-container {
  transition: none !important;
}

.noFileSelectedMessage {
  color: red;
  text-align: center;
  font-size: large;
  font-weight: 600;
}

.editorFloatButtonGroup {
  flex: 1;
  justify-content: center;
  gap: .5rem;
  display: flex;
}

.editorFloatButtonGroup > button {
  box-shadow: none;
  border: 1px solid #000;
  transition: transform .1s ease-in-out;
  position: static;
}

.editorFloatButtonGroup > button:hover {
  background-color: #000;
  border-color: #fff;
  transition: box-shadow .3s ease-in-out, background-color .3s ease-in-out, transform .3s ease-in-out;
  transform: scale(1.1);
  box-shadow: 0 0 10px #fff;
}

.editorFloatButtonGroup > button:active {
  transition: transform .1s ease-in-out;
  transform: scale(.95);
}

.editorFloatButtonGroup > button .ant-float-btn-icon {
  transition: transform .3s ease-in-out;
  transform: scale(1.2);
}

.editorFloatButtonGroup > button:hover .ant-float-btn-icon {
  transition: transform .3s ease-in-out, box;
  transform: scale(1.3);
  color: #fff !important;
}

.runProgramBtn {
  justify-content: center;
  align-items: center;
  display: flex;
  transition: transform .1s ease-in-out, color .1s ease-in-out !important;
}

.runProgramBtn:active {
  transition: transform .1s ease-in-out !important;
  transform: scale(.95) !important;
  box-shadow: 0 0 15px #25ff25 !important;
}

.runProgramBtn .ant-float-btn-body {
  width: 35px;
  height: 35px;
  background-color: green !important;
}

.runProgramBtn .ant-float-btn-content {
  min-width: 42px;
  min-height: 42px !important;
}

.editorFloatButtonGroup .runProgramBtn .ant-float-btn-icon {
  color: #fff !important;
  transition: transform .3s ease-in-out !important;
  transform: scale(2.2)translate(0, .25px) !important;
}

.runProgramBtn:hover .ant-float-btn-body {
  background-color: #000 !important;
}

.editorFloatButtonGroup > .runProgramBtn:hover .ant-float-btn-icon {
  color: #25ff25 !important;
}

.editorFloatButtonGroup > .runProgramBtn:active .ant-float-btn-icon {
  color: #fff !important;
  transition: color .1s ease-in-out !important;
}

.saveIndicatorContainer {
  display: flex;
  position: absolute;
  top: .5rem;
  left: .5rem;
}

.saveIndicatorText {
  color: #fff;
}

.noDataFoundMessage {
  color: red;
  font-style: italic;
  font-weight: 600;
}

.editorOverlayContainer {
  background: var(--sidebar-bg-color);
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  justify-content: center;
  width: 100%;
  padding-bottom: .5rem;
  display: flex;
  position: relative;
}

.overlayCenteringContainer {
  cursor: auto;
  border-bottom-right-radius: 20%;
  border-bottom-left-radius: 20%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  width: fit-content;
  display: flex;
}

.submitProblemBtn {
  white-space: break-spaces;
  width: min-content;
  height: fit-content;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.confirmProblemModalContent {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.confirmModalWarningContainer {
  gap: 1rem;
  display: flex;
}

.solidExclamationSymbolConfirm {
  font-size: 1.25rem;
}

.editorProblemTitle {
  color: #000;
  text-align: center;
  margin-top: .5rem;
  font-weight: 600;
}

.bottomEditorButtons {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.ant-form-item {
  margin-bottom: 5px;
}

.loadingSpinnerContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

@media (width >= 1100px) {
  .submitProblemBtn {
    width: max-content;
  }
}

@media (width <= 1475px) {
  .editorProblemTitle {
    font-size: 1.5rem;
  }
}

@media (width <= 1275px) {
  .editorProblemTitle {
    font-size: 1.25rem;
  }
}

.ant-list-header {
  text-align: center;
}

.ant-list-header h4 {
  font-weight: bold;
}

.moduleSelectorContainer {
  width: 100%;
}

.moduleSelectorContainer > span {
  flex-direction: column;
  align-items: center;
  gap: .25rem;
  width: 100%;
  display: flex;
}

.moduleSelectorContainer > span > div {
  width: 100%;
}

.moduleSelectorContainer > span > .ant-divider {
  border-block-start: 1px solid #000;
  width: 100%;
  margin: 0;
}

.listContainer {
  color: #fff;
  background-color: #000;
  min-height: 200px;
  max-height: 200px;
  padding: 0 .25rem 1rem;
  overflow: hidden auto;
}

.listItem {
  text-align: center;
  background-color: #7c7c7c;
  border: 2px solid #000;
}

.listItem:last-child:not(:only-child) {
  border-radius: 0 0 8px 8px;
}

.listItem .removeLibraryIcon {
  opacity: 0;
  transition: opacity .5s, transform .3s ease-in-out;
}

.listItem:hover .removeLibraryIcon {
  opacity: 1;
}

.removeLibraryIcon:hover {
  transform: scale(1.2)rotate(90deg);
}

.removeLibraryIcon:focus {
  opacity: 1;
}

.toolTip:after {
  content: attr(data-tooltip);
  visibility: hidden;
  opacity: 0;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 5px;
  width: max-content;
  padding: 5px;
  font-size: .75rem;
  font-style: italic;
  transition: visibility, opacity .2s linear;
  position: absolute;
  transform: translateX(10px)translateY(-5px);
}

.toolTip:hover:after {
  visibility: visible;
  opacity: .75;
}

.datasetLoadingOverlay .ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text {
  max-height: none;
}

.datasetLoadingOverlay .ant-spin-dot-item {
  background-color: #78b0ff;
}

.fileUploadContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fileUploadErrorMessage {
  color: red;
  background-color: #000;
  padding: .25rem;
  font-size: 12px;
}

.recentDatasetOptionOverallContainer {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.recentDatasetOptionNameAndDateAccessed {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.recentDatasetOptionName {
  font-size: 14px;
  font-weight: bold;
}

.recentDatasetOptionDate {
  color: #b5b5b5;
  font-size: 12px;
  font-style: italic;
}

.recentDatasetOptionDeleteButton {
  align-self: flex-start;
  justify-content: center !important;
  align-items: center !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 10px !important;
  display: flex !important;
}

.deleteRecentDatasetIcon {
  width: 12px;
}

.console {
  color: #32cd32;
  white-space: pre;
  cursor: text;
  background-color: #000;
  border-radius: 4px;
  width: 100%;
  padding: 10px 10px 1.25rem;
  font-family: Courier New, Courier, monospace;
  overflow: auto;
  -webkit-user-select: text !important;
  user-select: text !important;
}

.consoleViewerOuterContainer > .consoleOutputContainer {
  cursor: text;
  flex: 1;
  display: flex;
  overflow: auto;
}

.consoleLoadingOverlay {
  background-color: #000;
}

.consoleViewerOuterContainer .consoleLoadingOverlay {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.consoleOutputContainer {
  flex-direction: column;
  display: flex;
}

.consoleOutputContainer .ant-spin-text {
  font-style: italic;
  text-shadow: none !important;
  text-align: start !important;
  color: #c7c7c7 !important;
  width: fit-content !important;
  top: 80% !important;
  left: 6% !important;
}

.consoleOutputContainer .ant-spin-dot {
  text-align: start !important;
  top: 80% !important;
  left: 15% !important;
}

.consoleOutputContainer .ant-spin-dot-item {
  opacity: .1 !important;
  background-color: #71acff !important;
}

.consoleOutputContainer .ant-spin-blur {
  opacity: .3 !important;
}

.consoleOutputContainer .ant-spin-container {
  flex: 1;
}

.console pre {
  white-space: pre-wrap;
}

.mainControlContainer {
  justify-content: space-between;
  gap: .5rem;
  max-width: 100%;
  height: 200px;
}

.selectFeaturesContainer {
  flex-direction: column;
  flex: 1;
  max-width: 50%;
  display: flex;
}

.featuresCheckboxList {
  flex-flow: column;
  display: flex;
  overflow: hidden auto;
}

.selectTargetContainer {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.topBottomDivider {
  margin: 0 2px;
}

.halfContainer {
  text-align: start;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.targetVariableContainer {
  flex: 40%;
}

.testSizeRatioContainer {
  flex: 60%;
}

.inputNumberRow {
  margin: 0 auto;
}

.testTrainSplitHeader {
  font-size: 1rem;
  font-weight: 600;
}

.selectFeaturesContainer .testTrainSplitHeader {
  margin: .5rem 0;
}

.featuresErrorWindow {
  color: red;
  background-color: #000;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: .5rem;
  display: flex;
}

.dataframeLoadingContainer {
  color: red;
  background-color: #000;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: .5rem;
  display: flex;
}

.dataframeLoadingOverlay {
  background-color: #000;
}

.dataframeLoadingOverlay .ant-spin-text {
  font-style: italic;
  text-shadow: none !important;
  text-align: center !important;
  color: #fff !important;
}

.dataframeLoadingOverlay .ant-spin-dot {
  text-align: center !important;
}

.dataframeLoadingOverlay .ant-spin-dot-item {
  background-color: #71acff !important;
}

.dataframeLoadingOverlay .ant-spin-blur {
  opacity: .3 !important;
}

.successDfMessage {
  color: green;
}

.labelSwitchContainer {
  justify-content: flex-end;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.labelContainer > .ant-select, .labelContainer > .ant-input-number {
  width: 100px;
}

.errorDisplayContainer {
  color: red;
  background-color: #000;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding: .5rem;
  display: flex;
}

.successVisualizer {
  color: green;
}

.singlePlotContainer {
  border: 2px solid #000;
  border-radius: .25rem;
  flex-direction: column;
  margin-top: .5rem;
  padding: .25rem;
  display: flex;
}

.singlePlotContainer .saveBtn {
  align-self: flex-end;
}

.singleStoredImage {
  flex-direction: column;
  display: flex;
}

.imageGalleryItem {
  position: relative;
}

.imageGalleryItem .deleteButton {
  z-index: 2;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.imageGalleryItem:hover .deleteButton {
  display: block;
}

.visualizerModal > .ant-modal-content, .imageGalleryModal > .ant-modal-content {
  max-height: 95vh;
  overflow-y: auto;
}

.gallery-caption {
  text-align: center;
  color: #666;
  margin-top: 8px;
  font-size: .8rem;
}

.ant-image-preview-mask, .ant-image-preview-wrap {
  z-index: 2001 !important;
}

.ant-image-preview-operations-wrapper {
  z-index: 2002 !important;
}

.mainContainer.problemDescriptionContainer {
  justify-content: start;
}

.backToProblemsButton {
  align-self: flex-start;
}

.titleContainer {
  width: 90%;
}

.descriptionContainer {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  width: 90%;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
}

.descriptionContainer > img {
  align-self: center;
  width: 100%;
  max-width: 800px;
  height: auto;
  margin-bottom: 2rem;
}

.descriptionContainer > p {
  text-align: start;
  margin-bottom: 1rem;
}

.startButton {
  color: #fff;
  background-color: #52c41a;
  border-color: #52c41a;
  font-weight: 600;
}

.startButton:hover, .startButton:focus {
  color: #fff !important;
  background-color: #43a047 !important;
  border-color: #43a047 !important;
  box-shadow: 0 0 10px 1px #3bff21ac !important;
}

.startButton:active {
  background-color: #388e3c;
  border-color: #388e3c;
}

.ant-drawer-content-wrapper {
  min-height: 25vh;
  max-height: 90vh;
  width: auto !important;
}

.ant-drawer-content-wrapper.no-transition, .ant-drawer-mask.no-transition {
  cursor: ns-resize !important;
  transition: none !important;
}

.resizeBar.problemDescriptionResizeBar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.resizeHandle {
  vertical-align: middle;
  cursor: ns-resize;
  color: #fff;
  text-align: center;
  background-color: #4c4c4c;
  border: 2px solid #fff;
  border-top: 0;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 20px;
  font-size: 12px;
  display: flex;
  position: fixed;
  top: 592.5px;
  left: calc(50vw - 12.5% + 150px);
  transform: translateX(-50%);
}

.ant-drawer-body.problemDescriptionDrawerBody {
  border-bottom: 2px solid #fff;
  padding-bottom: 0 !important;
}

.drawerOuterContainer {
  flex: 1;
}

:root {
  --chatbot-margin: .25rem;
}

.ant-drawer-body.chatbotBody {
  padding: 0;
}

.chatbotContainer {
  background-color: #c8c8c8;
  border-bottom: 3px solid #000;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.chatbotHeader {
  justify-content: space-between;
  padding: .5rem 1rem;
  display: flex;
}

.spacer {
  visibility: hidden;
  width: 118.21px;
}

.chatbotHeaderTitle {
  text-align: center;
}

.chatbotHeaderCloseButton {
  color: #fff;
  background-color: red;
  font-weight: 600;
}

.chatbotMainContent {
  background-color: #525252;
  border: 3px solid #000;
  border-bottom: none;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  flex: 1;
  min-height: 0;
  margin: 0 .5rem;
  padding: 1rem;
  display: flex;
}

.chatbotMessagesAndInputContainer {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.chatbotMessagesAndInputInternalPositioningContainer {
  flex-direction: column;
  justify-content: space-between;
  gap: .5rem;
  width: 100%;
  height: 100%;
  display: flex;
}

.chatbotMessagesContainer {
  color: #fff;
  background-color: #000;
  flex-direction: column;
  flex: 1;
  gap: 1.25rem;
  padding: 1rem 1rem 2rem;
  display: flex;
  overflow: hidden auto;
}

.chatbotInputContainer {
  gap: .25rem;
  width: 100%;
  padding: .25rem;
  display: flex;
}

.chatbotInput {
  flex: 1;
}

.chatbotHeaderCloseButton:hover {
  color: #000 !important;
}

.message-list-item {
  color: #fff;
  border-radius: 1rem;
  max-width: 75%;
  padding: .75rem;
  list-style: none;
  position: relative;
}

.message-list-item p:last-child {
  margin: 0;
}

.message-list-item p, .message-list-item ol {
  margin-bottom: .5rem;
}

.message-list-item li {
  margin-bottom: .25rem;
}

.request-message {
  background-color: #0d6efd;
  align-self: flex-end;
}

.request-message:before {
  content: "";
  z-index: 0;
  background: inherit;
  border-bottom-left-radius: .6rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  bottom: 0;
  right: -8px;
}

.request-message:after {
  content: "";
  z-index: 1;
  background: #000;
  border-bottom-left-radius: .5rem;
  width: .5rem;
  height: 1rem;
  position: absolute;
  bottom: 0;
  right: -8px;
}

.response-message {
  background-color: #3d3f41;
  align-self: flex-start;
}

.response-message:before {
  content: "";
  z-index: 0;
  background: inherit;
  border-bottom-right-radius: .6rem;
  width: 1rem;
  height: 1rem;
  position: absolute;
  bottom: 0;
  left: -8px;
}

.response-message:after {
  content: "";
  z-index: 1;
  background: #000;
  border-bottom-right-radius: .5rem;
  width: .5rem;
  height: 1rem;
  position: absolute;
  bottom: 0;
  left: -8px;
}

pre > code {
  white-space: pre-wrap;
}

.addNewConversationBtn {
  align-items: center;
  display: flex;
}

.chatbotConversationContainer button.addNewConversationBtn:hover {
  color: green !important;
  border-color: green !important;
}

.newConversationPlusIcon {
  color: green;
  font-size: 16px;
  transition: transform .2s ease-in-out;
}

.addNewConversationBtn:hover .newConversationPlusIcon {
  transform: scale(1.2);
}

.chatbotConversationContainer {
  background-color: #c8c8c8;
  border: 3px solid #000;
  border-radius: .25rem;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  width: 40%;
  min-width: 200px;
  max-width: 300px;
  padding: 1rem;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
}

.chatbotConversationContainer.collapsed {
  width: 100px;
  min-width: 100px;
}

.conversation-list {
  color: #fff;
  background-color: #000;
  border-radius: .25rem;
  width: 100%;
  min-width: 175px;
  padding: .5rem;
  overflow: hidden auto;
}

.conversation-list-item {
  cursor: pointer;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  background-color: gray;
  border-radius: .5rem;
  padding: .5rem;
  overflow: hidden;
}

.conversation-list-item:not(:last-child) {
  margin-bottom: .5rem;
}

.conversation-list-item:hover {
  background-color: #3d3f41;
}

.conversation-list-item.active-conversation {
  background-color: #0d6efd;
}

.no-conversations-msg {
  opacity: .7;
  text-align: center;
  font-style: italic;
}

.messageTimeStamp {
  text-wrap: nowrap;
  font-size: smaller;
  font-style: italic;
  position: absolute;
  bottom: -1.25rem;
}

.request-message .messageTimeStamp {
  right: 0;
}

.response-message .messageTimeStamp {
  left: 0;
}

.collapseConversationBarBtn {
  position: absolute;
  top: 0;
  right: 0;
}

.collapsed > .collapseConversationBarBtn {
  position: static;
}

.collapsedConversationBarPlaceholder {
  text-align: center;
}

.resizeBar {
  cursor: row-resize;
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;
  background: #444857;
  flex-shrink: 0;
  height: 14px;
  position: relative;
}

.resizeBar:before {
  content: "";
  border-block: 1px solid #fff;
  width: 2rem;
  height: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chatbotDrawerDragging {
  transition: none;
}

.typing-indicator {
  align-items: center;
  display: flex;
}

.typing-indicator span {
  background-color: #1890ff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  animation: 1.4s infinite both blink;
}

.typing-indicator span:nth-child(2) {
  animation-delay: .25s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: .5s;
}

@keyframes blink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
    transform: scale(1.3);
  }

  100% {
    opacity: .2;
  }
}

@media (width >= 1925px) {
  .chatbotMessagesAndInputInternalPositioningContainer {
    width: 90%;
  }
}

@media (width >= 2200px) {
  .chatbotMessagesAndInputInternalPositioningContainer {
    width: 80%;
  }
}

pre[class*="language-"].line-numbers {
  counter-reset: linenumber;
  padding-left: 3.8em;
  position: relative;
}

pre[class*="language-"].line-numbers > code {
  white-space: inherit;
  position: relative;
}

.line-numbers .line-numbers-rows {
  pointer-events: none;
  letter-spacing: -1px;
  -webkit-user-select: none;
  user-select: none;
  border-right: 1px solid #999;
  width: 3em;
  font-size: 100%;
  position: absolute;
  top: 0;
  left: -3.8em;
}

.line-numbers-rows > span {
  counter-increment: linenumber;
  display: block;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  text-align: right;
  padding-right: .8em;
  display: block;
}

div.code-toolbar {
  position: relative;
}

div.code-toolbar > .toolbar {
  z-index: 10;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: .3em;
  right: .2em;
}

div.code-toolbar:hover > .toolbar, div.code-toolbar:focus-within > .toolbar {
  opacity: 1;
}

div.code-toolbar > .toolbar > .toolbar-item {
  display: inline-block;
}

div.code-toolbar > .toolbar > .toolbar-item > a {
  cursor: pointer;
}

div.code-toolbar > .toolbar > .toolbar-item > button {
  color: inherit;
  font: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: none;
  border: 0;
  padding: 0;
  line-height: normal;
  overflow: visible;
}

div.code-toolbar > .toolbar > .toolbar-item > a, div.code-toolbar > .toolbar > .toolbar-item > button, div.code-toolbar > .toolbar > .toolbar-item > span {
  color: #bbb;
  background: #e0e0e033;
  border-radius: .5em;
  padding: 0 .5em;
  font-size: .8em;
  box-shadow: 0 2px #0003;
}

div.code-toolbar > .toolbar > .toolbar-item > a:hover, div.code-toolbar > .toolbar > .toolbar-item > a:focus, div.code-toolbar > .toolbar > .toolbar-item > button:hover, div.code-toolbar > .toolbar > .toolbar-item > button:focus, div.code-toolbar > .toolbar > .toolbar-item > span:hover, div.code-toolbar > .toolbar > .toolbar-item > span:focus {
  color: inherit;
  text-decoration: none;
}

code[class*="language-"], pre[class*="language-"] {
  color: #f8f8f2;
  text-shadow: 0 1px #0000004d;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"] {
  border-radius: .3em;
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #272822;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #8292a2;
}

.token.punctuation {
  color: #f8f8f2;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted {
  color: #f92672;
}

.token.boolean, .token.number {
  color: #ae81ff;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #a6e22e;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string, .token.variable {
  color: #f8f8f2;
}

.token.atrule, .token.attr-value, .token.function, .token.class-name {
  color: #e6db74;
}

.token.keyword {
  color: #66d9ef;
}

.token.regex, .token.important {
  color: #fd971f;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.dockMenuOverallContainer {
  background-color: var(--sidebar-bg-color);
  border-bottom: 3px solid #000;
  flex-direction: column;
  width: 200px;
  height: calc(100vh - 3px);
  transition: all .3s;
  display: flex;
}

.dockMenuOverallContainer.sidebarCollapsed {
  border: none;
  width: 0;
  padding: 0;
  transition: none;
}

.dockMenuOverallContainer > h1 {
  padding-top: .5rem;
  padding-left: .5rem;
}

.dockMenuElementList {
  margin-top: 1rem;
  overflow-y: auto;
}

.dockMenuElementList ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dockMenuElementList ul:not(.subCategoryList) {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.dockMenuElementList h3 {
  color: #333;
  margin-bottom: 10px;
  padding: .5rem;
  font-size: 1.2rem;
}

.dockMenuElementList > li {
  border-top: 3px solid #000;
  margin-bottom: 1rem;
}

.dockMenuElement {
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  max-height: 200px;
  padding: .5rem;
  transition: background-color .3s;
  display: flex;
  overflow: hidden;
}

.dockMenuElement:hover {
  color: #fff;
  background-color: #333;
}

.dockMenuElement img {
  object-fit: contain;
  border-radius: .25rem;
  flex: 1;
  width: fit-content;
  min-height: 0;
}

.subCategoryList .subCategory {
  margin-top: 5px;
  padding-left: 20px;
}

.siderContainer {
  border: 3px solid #000;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  display: flex;
  flex: 0 0 fit-content !important;
  width: fit-content !important;
  min-width: 50px !important;
  max-width: none !important;
}

.siderContainer.siderCollapsed {
  flex: 0 0 110px !important;
  max-width: 110px !important;
}

.ant-layout-sider-children {
  flex: 1;
  min-height: 0;
}

.ant-layout-sider-trigger {
  max-width: 110px;
}

.siderTabs {
  width: fit-content;
  height: 100%;
  padding-bottom: 5rem;
  transition: all .3s;
}

.ant-tabs-tab-btn {
  color: #fff;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #32cd32 !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #32cd32;
}

.siderTabs.siderTabsCollapsed {
  width: 80px;
  margin-top: 3rem;
  padding-bottom: 10rem;
}

.siderCollapseBtn, .siderProblemsBtn, .siderLogoutBtn {
  z-index: 2;
  width: 110px !important;
  position: absolute !important;
}

.siderProblemsBtn {
  bottom: 36px;
}

.topSiderButton {
  top: 0;
  right: 0;
}

.topSiderButton:not(.siderCollapseBtnCollapsed) {
  border-radius: 0 0 0 .5rem;
}

.bottomSiderButton {
  bottom: 4.5rem;
  right: unset;
  left: 0;
}

.bottomSiderButton:not(.siderCollapseBtnCollapsed) {
  bottom: 0;
  right: 0;
  left: unset;
  border-radius: .5rem 0 0;
}

.bottomSiderButton.ajustForOverflow {
  right: 1.5rem;
}

.siderLogoutBtn {
  bottom: 0;
}

.ant-tabs-nav {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.ant-tabs-tab:hover {
  background-color: #32cd32;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #000 !important;
}

.nodePreviewTitle {
  text-align: center;
}

.blockPopoverOverlay {
  border: 3px solid #608eff;
  border-radius: .5rem;
  box-shadow: 0 0 8px 1px #608eff;
}

.blockPopoverOverlay .ant-popover-inner {
  border-radius: .5rem;
}

.blockPopoverContent {
  border-top: 1px solid #0505050f;
  max-width: 500px;
  padding-top: .5rem;
}

.blockDescriptionContainer h3 {
  text-align: center;
}

.blockDescriptionText {
  font-style: italic;
}

.inputOutputContainer, .dividerContainer {
  align-items: stretch;
  display: flex;
}

.dividerContainer .verticalDivider {
  height: 100%;
}

.divider {
  margin: .5rem 0;
}

.inputsColumn, .outputsColumn {
  flex: 1;
}

.emptyInputOutput {
  flex: none;
}

.inputsColumn, .outputsColumn {
  min-width: 100px;
}

.inputOutputTitle {
  text-decoration: underline;
}

.siderContainer .siderTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
  padding-left: 0;
}

.blockPreRequisites {
  list-style: square inside;
}

@media (width >= 1200px) {
  .dockMenuOverallContainer {
    width: 250px;
  }

  .siderContainer .siderTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding-left: 24px;
  }
}

@media (width >= 1440px) {
  .dockMenuOverallContainer {
    width: 300px;
  }
}

@media (width >= 1900px) {
  .dockMenuOverallContainer {
    width: 350px;
  }
}

.sidebarContainer {
  background-color: var(--sidebar-bg-color);
  text-align: center;
  flex-direction: column;
  width: 200px;
  height: calc(100vh - 6px);
  padding: 1rem;
  transition: all .3s;
  display: flex;
  overflow: auto;
}

.sidebarContainer.sidebarCollapsed {
  border: none;
  width: 0;
  padding: 0;
  transition: none;
}

.logoutContainer {
  z-index: 10;
  background-color: #e5f0ff;
  border-radius: .25rem;
  margin-top: auto;
  padding: .25rem;
  position: sticky;
  bottom: 0;
}

.roadmapHeader {
  margin: 1rem;
}

.step {
  min-height: 100px;
}

.ant-steps-item-icon {
  margin-inline-end: .5rem;
}

.ant-steps-item-content {
  padding-left: .5rem;
}

.ant-steps-item-title {
  margin-bottom: .25rem !important;
  padding-inline-end: .5rem !important;
  line-height: 20px !important;
}

.ant-steps-item-active .ant-steps-item-content {
  background: #fff;
  border-radius: 5px;
  outline: 2px solid #1677ff;
  transition: padding-left .2s ease-in-out;
}

.ant-steps-item-active .ant-steps-item-title {
  font-weight: 600;
}

.ant-steps-item-active .unfinishedTaskIcon {
  background-color: #fff;
  border-color: #1677ff;
}

.taskProgressContainer {
  margin-bottom: 1rem;
}

.unfinishedTaskIcon {
  border: 2px solid #929292;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-block;
}

.stepDescriptionText {
  white-space: pre-wrap;
}

@media (width >= 1200px) {
  .sidebarContainer {
    width: 250px;
  }
}

@media (width >= 1440px) {
  .sidebarContainer {
    width: 300px;
  }
}

.notesContainerOverall {
  background-color: var(--sidebar-bg-color);
  border-bottom: 3px solid #000;
  flex-direction: column;
  width: 200px;
  height: calc(100vh - 3px);
  padding: .5rem;
  transition: all .3s;
  display: flex;
}

.notesContainerOverall > h1 {
  margin-bottom: 1rem;
}

.notesListContainer {
  padding-bottom: 2rem;
  overflow: auto;
}

.notesListContainer.adjustForScrollBar {
  padding-right: .5rem;
}

.notesList {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.noteInput {
  padding: .5rem .75rem;
}

.editableNoteContainer {
  background-color: #fff8dc;
  border-radius: .5rem;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
  display: flex;
}

.editableNoteContainer h4 {
  border-bottom: 1px solid gray;
  margin-bottom: 0;
}

.noteContentAndIconContainer {
  align-items: stretch;
  gap: .5rem;
  width: 100%;
  display: flex;
  position: relative;
}

.editableNoteContainer .readOnlyNoteContent {
  white-space: pre-wrap;
  flex: 1;
  margin-bottom: 1.5rem;
}

.editableNoteContainer .readOnlyNoteContent.adjustForCollapseButton {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.readOnlyTitleContainer {
  flex-direction: column;
  display: flex;
}

.noteDateModified {
  text-align: end;
  font-size: .75rem;
  font-style: italic;
  text-decoration: underline;
}

.noteErrorMessage {
  color: red;
  background-color: #fff;
  border-radius: .25rem;
  width: fit-content;
  padding: 0 .25rem;
  font-size: .875rem;
}

.saveNoteBtn {
  margin-top: .5rem;
}

.expandCollapseNoteButton {
  cursor: pointer;
  color: #1890ff;
  border: 1px solid gray;
  border-radius: .25rem;
  padding: 0 .55rem;
  position: absolute;
  bottom: 0;
  right: 45%;
}

.expandCollapseNoteButton:hover {
  background-color: #fff;
}

.noteButtonsContainer {
  flex-direction: column;
  justify-content: center;
  gap: .25rem;
  display: flex;
}

@media (width >= 1200px) {
  .notesContainerOverall {
    width: 250px;
  }

  .siderContainer .siderTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding-left: 24px;
  }
}

@media (width >= 1440px) {
  .notesContainerOverall {
    width: 300px;
  }
}

@media (width >= 1900px) {
  .notesContainerOverall {
    width: 350px;
  }
}

.rightSideContainer {
  border: 3px solid #000;
  flex: 0 0 30%;
  max-width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column !important;
}

.rightSideContainer.siderCollapsed {
  flex: 0 0 110px;
}

.rightSiderContainer {
  flex-direction: column;
  height: 100%;
  display: flex;
  background-color: #000 !important;
  flex: 1 !important;
  width: 100% !important;
  min-width: 110px !important;
  max-width: none !important;
}

.rightSiderContainer.siderCollapsed {
  background-color: var(--sidebar-bg-color) !important;
}

.sideBarPlaceHolderText {
  color: #000;
  text-align: center;
}

.rightSiderContainer > .ant-layout-sider-children {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: .25rem;
  display: flex;
}

.rightSiderContainer.siderCollapsed > .ant-layout-sider-children {
  width: 110px;
}

.rightSiderCollapseBtn {
  min-width: 110px;
  position: absolute;
}

.rightSiderContainer:not(.siderCollapsed) .rightSiderCollapseBtn {
  border-radius: 0 !important;
}

.rightSiderContainer .rightSiderCollapseBtn.topCollapseBtn {
  top: 0;
  border-bottom-right-radius: .5rem !important;
}

.rightSideContainer .rightSiderCollapseBtn.bottomCollapseBtn {
  bottom: 0;
  border-top-right-radius: .5rem !important;
}

.rightColumnHalfContainer {
  background-color: var(--sidebar-bg-color);
  flex-direction: column;
  flex: 1;
  max-height: 50%;
  display: flex;
  overflow: auto;
}

.codePreviewOuterContainer {
  border-radius: .5rem .5rem 0 0;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  padding: .5rem;
  display: flex;
}

.consoleViewerOuterContainer {
  border-radius: 0 0 .5rem .5rem;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  padding: .5rem;
  display: flex;
}

.rightColumnHalfContainer h2 {
  text-align: center;
  flex: none;
}

.codePreviewInnerContainer {
  white-space: pre-line;
  cursor: text;
  flex: 1;
  font-size: .75rem;
  overflow: auto;
}

.codePreviewTitle {
  align-self: flex-end;
  margin-right: 1rem;
}

@media (width >= 1200px) {
  .codePreviewTitle {
    margin-right: 2rem;
  }
}

@media (width >= 1440px) {
  .codePreviewTitle {
    align-self: center;
    margin: 0;
  }

  .codePreviewInnerContainer {
    font-size: .9rem;
  }
}

@media (width >= 1920px) {
  .codePreviewInnerContainer {
    font-size: 1rem;
  }
}

.moduleViewerContent {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.moduleViewerContent * {
  transition: none !important;
}

.moduleViewerContent .resizeBar {
  width: 100%;
}

.moduleViewerContent .ant-divider {
  margin: 1rem 0;
}

.moduleViewerTitle {
  text-align: center;
}

.moduleViewerForm {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: .5rem;
  width: 100%;
  max-width: 550px;
  padding: 1rem;
}

.searchBarAndClearButtonContainer {
  flex: 1;
  max-width: 430px;
}

.sortByContainer {
  gap: 1rem;
  display: flex;
}

.moduleViewerHeader {
  border-bottom: 1px solid gray;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 1rem;
  display: flex;
  position: sticky;
}

.moduleViewerHeader.singleModuleHeader {
  justify-content: start !important;
  gap: 1rem !important;
}

.closeModuleViewerButton, .moduleViewerHeaderSpacer {
  color: #fff;
  background-color: red;
  width: 160px;
  font-weight: 600;
}

.closeModuleViewerButton:hover {
  color: #000 !important;
}

.moduleViewerHeaderSpacer {
  display: none;
}

.moduleSearchAndResultsContainer {
  background-color: #525252;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  overflow: auto;
}

.moduleSearchResultsContainer {
  width: 100%;
  max-width: 550px;
}

.moduleListItem {
  background-color: #000;
  border: 1px solid gray;
  flex-direction: column;
  gap: .25rem;
  width: 100%;
  display: flex;
}

.moduleListItem:hover, .moduleListItem:hover :not(button, button *, .moduleStatusContainer) {
  background-color: #252525;
}

.moduleListItemTop {
  gap: 1rem;
  width: 100%;
  display: flex !important;
}

.moduleDetails {
  flex: none;
  gap: 1rem;
  min-width: 90px;
}

.moduleListItemTitle {
  text-align: start;
}

.moduleTitleAndDescription {
  flex-direction: column;
  flex: 1;
  align-items: start;
  width: calc(100% - 90px - 1rem);
  display: flex;
}

.moduleOverview {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.individualModuleContainer {
  background: #525252;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}

.moduleViewerModuleContent {
  background-color: #fff;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  max-width: 700px;
  height: fit-content;
  padding: 1rem;
  display: flex;
}

.moduleTextContent {
  text-align: start;
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.individualModuleHeader {
  border-bottom: 1px solid gray;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.noResultsContainer {
  text-align: center;
  margin-top: 1rem;
}

.moduleTextContent pre {
  overflow: auto;
}

.moduleTextContent h6 {
  font-size: 1rem;
}

.moduleTextContent ul, .moduleTextContent pre {
  margin: 0;
}

.moduleHeaderButtonContainer {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.backToTopButton {
  z-index: 1000;
  display: block;
  position: absolute;
  top: 5.25rem;
  right: 22px;
}

.moduleStatusContainer {
  color: #000;
  background-color: gray;
  border-radius: .25rem;
  padding: 0 .25rem;
}

.moduleStatusContainer.moduleInProgress {
  color: #000;
  background-color: #f0ad4e;
}

.moduleStatusContainer.moduleComplete {
  color: #fff;
  background-color: #007600;
}

.markModuleCompleteButton {
  width: fit-content;
  margin: 0 auto;
}

.markModuleCompleteButton.completeModule {
  color: #fff;
  background-color: #009200;
}

.moduleSearchRadioGroup {
  margin-left: 1rem;
}

@media screen and (width >= 1400px) {
  .moduleViewerHeaderSpacer {
    display: inherit;
  }

  .moduleViewerHeader {
    justify-content: space-between;
  }
}

.mainContainer {
  text-align: center;
  background-color: #dadada;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  width: 70%;
  height: 90vh;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: auto;
  box-shadow: 0 4px 8px #0000001a;
}

.ant-drawer-body .mainContainer {
  gap: 2rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: start !important;
  height: auto !important;
}

.titleContainer {
  background-color: #fff;
  border-radius: .5rem;
  padding: 1rem;
}

.ant-drawer-body .titleContainer {
  padding: 1rem;
}

.problemListContainer > .ant-card-body {
  flex-direction: column;
  gap: 1rem;
  display: flex;
  overflow: auto;
}

.problemListContainer {
  flex-direction: column;
  max-width: 70%;
  max-height: 100%;
  display: flex;
}

.problemCard {
  width: 100%;
  font-size: 1.25rem;
}

.problemCard:hover, .problemCard:focus {
  outline: 3px solid #6f67be;
  box-shadow: 0 1px 2px -2px #00000040, 0 3px 6px #0003, 0 5px 12px 4px #00000026;
}

.modalButtonContainer {
  justify-content: space-evenly;
  display: flex;
}

.modalParagraphText {
  text-align: center;
}

.customModalBody {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.reviewDescriptionOrResumeProblemModal .ant-modal-title {
  font-size: 1.25rem;
}

.problemSelectionLogoutBtn {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.problemSubmittedDateContainer {
  position: relative;
}

.problemSubmittedDateContainer > span {
  font-size: .75rem;
  font-style: italic;
  position: absolute;
}

.submittedDateText {
  right: 0;
}

.tasksCompletedText {
  left: 0;
}

.attemptNumberText {
  text-align: end;
  font-size: .75rem;
  font-style: italic;
}

@media (width >= 1440px) {
  .mainContainer {
    text-align: center;
    width: 60%;
    height: 90vh;
    padding: 20px;
  }

  .problemListContainer {
    max-width: 60%;
  }
}

@media (width >= 1920px) {
  .mainContainer {
    text-align: center;
    background-color: #dadada;
    border-radius: 5px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    height: 90vh;
    padding: 20px;
    display: flex;
    position: relative;
    box-shadow: 0 4px 8px #0000001a;
  }

  .titleContainer {
    background-color: #fff;
    border-radius: .5rem;
    padding: 2rem;
  }

  .problemListContainer {
    max-width: 60%;
  }
}

.problemSubmissionMainContainer {
  background-color: gray;
  justify-content: center;
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: visible;
}

.problemSubmissionDetailsContainer {
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  line-height: 1.5;
  display: flex;
}

.problemSubmissionDetailsCard {
  flex: 1;
}

.referenceContainerForButtons {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
}

.problemSubmissionInnerContainer {
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  height: fit-content;
  padding: 1rem 0;
  display: flex;
}

.problemSubmissionSection {
  background-color: #fff;
  padding: 1rem;
}

.problemSubmissionSection .titleContainer h2 {
  margin-bottom: 1rem;
  text-decoration: underline;
}

.problemSubmissionSection > h2 {
  font-weight: 700;
}

.problemSubmissionDivider {
  margin: .5rem 0;
}

.problemSubmissionInnerContainer .titleContainer {
  width: 100%;
  padding: 0;
}

.language-python.problemSubmissionCode {
  margin: 0;
  font-size: .8rem;
}

.problemSubmissionInnerContainer .console {
  padding-bottom: 0;
  font-size: .8rem;
}

.problemTasksContainer {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.completedTaskItem {
  align-items: center;
  gap: 1rem;
  padding: .5rem;
  display: flex;
}

.problemSubmissionButtonPanel {
  z-index: 2;
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: absolute;
  top: 1rem;
  left: 0;
  overflow: visible;
  transform: translateX(calc(-100% - 1rem));
}

.reSubmitProblemContainer {
  align-items: center;
  gap: 1rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: flex;
}

.submissionSelectionContainer {
  align-items: center;
  gap: .5rem;
  margin: .5rem 0;
  display: flex;
}

.submissionSelectionContainer .ant-select-dropdown {
  width: max-content;
}

.editorScreenshotContainer {
  margin-bottom: 1rem;
}

.usedNodes {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  display: grid;
}

.usedNodeConnections {
  display: flex;
}

.usedNodeInputOutputContainer {
  flex: 1;
  padding: 0 .5rem;
}

.usedPaths {
  border: 1px solid #0505050f;
  border-radius: .5rem;
  flex-direction: column;
  gap: .5rem;
  width: fit-content;
  margin-bottom: .5rem;
  padding: .5rem;
  display: flex;
}

.noConnectionMessage {
  font-style: italic;
}

.boldText {
  font-weight: 700;
}
/*# sourceMappingURL=index.9022a3f7.css.map */
