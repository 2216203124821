/* create css variable for chatbot margin */
:root {
    --chatbot-margin: 0.25rem;
}

/* .chatbotOverallContainer {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
} */

.ant-drawer-body.chatbotBody {
    padding: 0;
}

.chatbotContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #c8c8c8;
    border-bottom: 3px solid black;
}

.chatbotHeader {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.spacer {
    width: 118.21px;
    visibility: hidden;
}

.chatbotHeaderTitle {
    text-align: center;
}

.chatbotHeaderCloseButton {
    background-color: red;
    color: white;
    font-weight: 600;
}

.chatbotMainContent {
    flex: 1;
    display: flex;
    background-color: #525252;
    margin: 0 0.5rem;
    padding: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    min-height: 0;
    border: 3px solid black;
    border-bottom: none;
}

.chatbotMessagesAndInputContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.chatbotMessagesAndInputInternalPositioningContainer {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
}

.chatbotMessagesContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1rem;
    padding-bottom: 2rem;
    color: white;
    background-color: black;
    overflow-y: auto;
    overflow-x: hidden;
}

.chatbotInputContainer {
    width: 100%;
    display: flex;
    gap: 0.25rem;
    padding: 0.25rem;
}

.chatbotInput {
    flex: 1;
}

.chatbotHeaderCloseButton:hover {
    color: black !important;
}

/* Message contents */

.message-list-item {
    position: relative;
    max-width: 75%;
    color: white;
    list-style: none;
    padding: 0.75rem;
    border-radius: 1rem;
}

.message-list-item.addPaddingForTimestamp {
}

.message-list-item p:last-child {
    margin: 0;
}

.message-list-item p {
    margin-bottom: 0.5rem;
}

.message-list-item ol {
    margin-bottom: 0.5rem;
}

.message-list-item li {
    margin-bottom: 0.25rem;
}

.request-message {
    align-self: flex-end;
    background-color: #0d6efd;
}

/* .request-message:hover {
    background-color: white !important;
    color: black !important;
} */

.request-message::before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 1rem;
    width: 1rem;
    background: inherit;
    border-bottom-left-radius: 0.6rem;
}

.request-message::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -8px;
    width: 0.5rem;
    height: 1rem;
    background: black;
    border-bottom-left-radius: 0.5rem;
}

.response-message {
    align-self: flex-start;
    background-color: #3d3f41;
}

.response-message::before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -8px;
    height: 1rem;
    width: 1rem;
    background: inherit;
    border-bottom-right-radius: 0.6rem;
}

.response-message::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -8px;
    width: 0.5rem;
    height: 1rem;
    background: black;
    border-bottom-right-radius: 0.5rem;
}

pre > code {
    white-space: pre-wrap;
}

.addNewConversationBtn {
    display: flex;
    align-items: center;
}

.chatbotConversationContainer button.addNewConversationBtn:hover {
    color: green !important;
    border-color: green !important;
}

.newConversationPlusIcon {
    font-size: 16px;
    color: green;
    transition: transform 0.2s ease-in-out;
}

.addNewConversationBtn:hover .newConversationPlusIcon {
    transform: scale(1.2);
}

.chatbotConversationContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    background-color: #c8c8c8;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 3px solid black;
    max-width: 300px;
    width: 40%;
    min-width: 200px;
    transition: all 0.2s ease-in-out;
}

.chatbotConversationContainer.collapsed {
    width: 100px;
    min-width: 100px;
}

.conversation-list {
    width: 100%;
    background-color: black;
    color: white;
    padding: 0.5rem;
    min-width: 175px;
    border-radius: 0.25rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.conversation-list-item {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: grey;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.conversation-list-item:not(:last-child) {
    margin-bottom: 0.5rem;
}

.conversation-list-item:hover {
    background-color: #3d3f41;
}

.conversation-list-item.active-conversation {
    background-color: #0d6efd;
}

.no-conversations-msg {
    font-style: italic;
    opacity: 0.7;
    text-align: center;
}

.messageTimeStamp {
    position: absolute;
    bottom: -1.25rem;
    text-wrap: nowrap;
    font-size: smaller;
    font-style: italic;
}

.request-message .messageTimeStamp {
    right: 0;
}

.response-message .messageTimeStamp {
    left: 0;
}

.collapseConversationBarBtn {
    position: absolute;
    top: 0;
    right: 0;
}

.collapsed > .collapseConversationBarBtn {
    position: static;
}

.collapsedConversationBarPlaceholder {
    text-align: center;
}

.resizeBar {
    background: #444857;
    height: 14px;
    cursor: row-resize;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    user-select: none;
}

.resizeBar::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 5px;
    border-block: 1px solid #fff;
}

.chatbotDrawerDragging {
    transition: none;
}

.typing-indicator {
    display: flex;
    align-items: center;
    /* margin-top: 20px; */
}

.typing-indicator span {
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border-radius: 50%;
    margin-right: 4px;
    animation: blink 1.4s infinite both;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.25s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.5s;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
        transform: scale(1.3);
    }
    100% {
        opacity: 0.2;
    }
}

/* media query greater than 1920px */
@media (min-width: 1925px) {
    .chatbotMessagesAndInputInternalPositioningContainer {
        width: 90%;
    }
}

/* media query greater than 2200 */
@media (min-width: 2200px) {
    .chatbotMessagesAndInputInternalPositioningContainer {
        width: 80%;
    }
}
