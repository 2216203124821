.mainControlContainer {
    height: 200px;
    gap: 0.5rem;
    justify-content: space-between;
    max-width: 100%;
}

.selectFeaturesContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 50%;
}

.featuresCheckboxList {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
}

.selectTargetContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    gap: 1rem;
}

.topBottomDivider {
    margin: 0 2px;
}

.halfContainer {
    text-align: start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.targetVariableContainer {
    flex: 1 1 40%;
}

.testSizeRatioContainer {
    flex: 1 1 60%;
}

.inputNumberRow {
    margin: 0 auto;
}

.testTrainSplitHeader {
    font-weight: 600;
    font-size: 1rem;
}

.selectFeaturesContainer .testTrainSplitHeader {
    margin: 0.5rem 0;
}

.featuresErrorWindow {
    background-color: black;
    color: red;
    padding: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
