.ant-drawer-content-wrapper {
    /* left: 300px !important; */
    /* right: 25% !important; */
    /* min-width: calc(100vw - 300px - 600px) !important; */
    width: auto !important;
    min-height: 25vh;
    max-height: 90vh;
}

.ant-drawer-content-wrapper.no-transition,
.ant-drawer-mask.no-transition {
    transition: none !important;
    cursor: ns-resize !important;
}

.resizeBar.problemDescriptionResizeBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.resizeHandle {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    font-size: 12px;
    top: 592.5px;
    left: calc((100vw - (25% - 300px)) / 2);
    transform: translateX(-50%);
    height: 20px;
    width: 200px;
    cursor: ns-resize;
    background-color: rgb(76, 76, 76);
    color: white;
    border: 2px solid #ffffff;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top: 0;
    text-align: center;
}

.ant-drawer-body.problemDescriptionDrawerBody {
    padding-bottom: 0px !important;
    border-bottom: 2px solid #ffffff;
}

.drawerOuterContainer {
    flex: 1;
}
