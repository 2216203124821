.singlePlotContainer {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    border: 2px solid black;
    padding: 0.25rem;
    border-radius: 0.25rem;
}

.singlePlotContainer .saveBtn {
    align-self: flex-end;
}

.singleStoredImage {
    display: flex;
    flex-direction: column;
}

.imageGalleryItem {
    position: relative;
}

.imageGalleryItem .deleteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: none;
}

.imageGalleryItem:hover .deleteButton {
    display: block;
}

.visualizerModal > .ant-modal-content,
.imageGalleryModal > .ant-modal-content {
    max-height: 95vh;
    overflow-y: auto;
}

.gallery-caption {
    text-align: center;
    margin-top: 8px;
    color: #666;
    font-size: 0.8rem;
}

.ant-image-preview-mask,
.ant-image-preview-wrap {
    z-index: 2001 !important;
}

.ant-image-preview-operations-wrapper {
    z-index: 2002 !important;
}
