.problemSubmissionMainContainer {
    overflow: visible;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: grey;
    position: relative;
}

.problemSubmissionDetailsContainer {
    margin: 1rem 0;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.problemSubmissionDetailsCard {
    flex: 1;
}

.referenceContainerForButtons {
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.problemSubmissionInnerContainer {
    height: fit-content;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
}

.problemSubmissionSection {
    background-color: white;
    padding: 1rem;
}

.problemSubmissionSection .titleContainer h2 {
    text-decoration: underline;
    margin-bottom: 1rem;
}

.problemSubmissionSection > h2 {
    font-weight: 700;
}

.problemSubmissionDivider {
    margin: 0.5rem 0;
}

.problemSubmissionInnerContainer .titleContainer {
    width: 100%;
    padding: 0;
}

.language-python.problemSubmissionCode {
    font-size: 0.8rem;
    margin: 0;
}
.problemSubmissionInnerContainer .console {
    font-size: 0.8rem;
    padding-bottom: 0;
}

.problemTasksContainer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.completedTaskItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem;
}

.problemSubmissionButtonPanel {
    border-radius: 0.5rem;
    overflow: visible;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 0;
    top: 1rem;
    transform: translateX(calc(-100% - 1rem));
    background-color: white;

    padding: 1rem;
}

.reSubmitProblemContainer {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.submissionSelectionContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
}

.submissionSelectionContainer .ant-select-dropdown {
    width: max-content;
}

.editorScreenshotContainer {
    margin-bottom: 1rem;
}

.usedNodes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.usedNodeConnections {
    display: flex;
}

.usedNodeInputOutputContainer {
    flex: 1;
    padding: 0 0.5rem;
}

.usedPaths {
    width: fit-content;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 0.5rem;
}

.noConnectionMessage {
    font-style: italic;
}

.boldText {
    font-weight: 700;
}
