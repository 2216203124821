.datasetLoadingOverlay .ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text {
    max-height: none;
}

.datasetLoadingOverlay .ant-spin-dot-item {
    background-color: #78b0ff;
}

.fileUploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fileUploadErrorMessage {
    color: red;
    font-size: 12px;
    background-color: black;
    padding: 0.25rem;
}

.recentDatasetOptionOverallContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.recentDatasetOptionNameAndDateAccessed {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.recentDatasetOptionName {
    font-size: 14px;
    font-weight: bold;
}

.recentDatasetOptionDate {
    font-size: 12px;
    color: #b5b5b5;
    font-style: italic;
}

.recentDatasetOptionDeleteButton {
    align-self: flex-start;
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 10px !important;
}

.deleteRecentDatasetIcon {
    width: 12px;
}
