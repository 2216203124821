.notesContainerOverall {
    width: 200px;
    background-color: var(--sidebar-bg-color);
    display: flex;
    height: calc(100vh - 3px); /* 3px is the border width */
    flex-direction: column;
    transition: all 0.3s;
    border-bottom: 3px solid black;
    padding: 0.5rem;
}

.notesContainerOverall > h1 {
    margin-bottom: 1rem;
}

.notesListContainer {
    overflow: auto;
    padding-bottom: 2rem;
}

.notesListContainer.adjustForScrollBar {
    padding-right: 0.5rem;
}

.notesList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.noteInput {
    /* margin-bottom: 0.5rem; */
    padding: 0.5rem 0.75rem;
}

.editableNoteContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: cornsilk;
}

.editableNoteContainer h4 {
    margin-bottom: 0;
    border-bottom: 1px solid grey;
}

.noteContentAndIconContainer {
    display: flex;
    align-items: stretch;
    gap: 0.5rem;
    width: 100%;
    position: relative;
}

.editableNoteContainer .readOnlyNoteContent {
    flex: 1;
    white-space: pre-wrap;
    margin-bottom: 1.5rem;
}

.editableNoteContainer .readOnlyNoteContent.adjustForCollapseButton {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.readOnlyTitleContainer {
    display: flex;
    flex-direction: column;
}

.noteDateModified {
    text-align: end;
    font-style: italic;
    font-size: 0.75rem;
    text-decoration: underline;
}
.noteErrorMessage {
    color: red;
    font-size: 0.875rem;
    background-color: white;
    width: fit-content;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
}

.saveNoteBtn {
    margin-top: 0.5rem;
}

.expandCollapseNoteButton {
    cursor: pointer;
    color: #1890ff;
    position: absolute;
    right: 45%;
    bottom: 0px;
    border: 1px solid grey;
    padding: 0 0.55rem;
    border-radius: 0.25rem;
}

.expandCollapseNoteButton:hover {
    background-color: #ffffff;
}

.noteButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;
}

/* media query min width 1200px */
@media (min-width: 1200px) {
    .notesContainerOverall {
        width: 250px;
    }
    .siderContainer .siderTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
        padding-left: 24px;
    }
}

/* media query min width 1440px */
@media (min-width: 1440px) {
    .notesContainerOverall {
        width: 300px;
    }
}

/* media query min width 1920px */
@media (min-width: 1900px) {
    .notesContainerOverall {
        width: 350px;
    }
}
