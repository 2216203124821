.labelSwitchContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
}

.labelContainer > .ant-select,
.labelContainer > .ant-input-number {
    width: 100px;
}
