#root {
    background-color: rgb(32, 32, 32);
    width: 100%;
    overflow: hidden;
}

/* CSS VARIABLES */
/* Main background color for outer elements */
:root {
    --sidebar-bg-color: #c9e4ff;
}

/* BOILER PLATE RESET */

html {
    box-sizing: border-box;
    font-size: 16px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

.App {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pageLoadingBackground {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
}

.pageLoadingBackground h1 {
    font-style: italic;
    font-weight: bolder;
}

.loginContainer {
    background: white;
    width: 700px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 0.5rem;
}

.loginContainer > form {
    max-width: 600px;
    width: 70%;
}

.welcomeUsernameMsg {
    z-index: 2;
    color: #fff;
    text-align: center;
    width: 110px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.5rem 0;
    background: #001529;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
}

.welcomeUsernameMsg.adjustUserWelcomeMsg {
    transition: all 0.25s ease-in-out;
    margin-top: 2.5rem;
}

.usernameBold {
    font-weight: 600;
}

#usernameText {
    display: inline-block;
    width: 110px;
    overflow: hidden;
    text-wrap: nowrap;
}

.BlockEditorContainer {
    display: flex;
    width: 100%;
}

.mainCenterColumn {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.editorContainer {
    flex: 1;
    position: relative;
    cursor: grab;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.editorContainer:active {
    cursor: grabbing !important;
}

.ant-form-item-control-input-content {
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* select menu dropdowns */

.selectDropDown {
    width: 100%;
}

.ant-select-dropdown {
    width: fit-content;
}

.ant-upload-list-item-container {
    transition: none !important;
}

.noFileSelectedMessage {
    color: red;
    text-align: center;
    font-size: large;
    font-weight: 600;
}

/* EDITOR BUTTONS */

.editorFloatButtonGroup {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    flex: 1;
}

.editorFloatButtonGroup > button {
    position: static;
    border: 1px solid black;
    box-shadow: none;
    transition: transform 0.1s ease-in-out;
}

.editorFloatButtonGroup > button:hover {
    background-color: black;
    border-color: white;
    transform: scale(1.1);
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
        transform 0.3s ease-in-out;
    box-shadow: 0 0 10px 0 white;
}

.editorFloatButtonGroup > button:active {
    transform: scale(0.95);
    transition: transform 0.1s ease-in-out;
}

.editorFloatButtonGroup > button .ant-float-btn-icon {
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

.editorFloatButtonGroup > button:hover .ant-float-btn-icon {
    color: white !important;
    transform: scale(1.3);
    transition: transform 0.3s ease-in-out, box;
}

.runProgramBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.1s ease-in-out, color 0.1s ease-in-out !important;
}

.runProgramBtn:active {
    transition: transform 0.1s ease-in-out !important;
    transform: scale(0.95) !important;
    box-shadow: 0 0 15px 0 rgb(37, 255, 37) !important;
}

.runProgramBtn .ant-float-btn-body {
    width: 35px;
    height: 35px;
    background-color: green !important;
}

.runProgramBtn .ant-float-btn-content {
    min-width: 42px;
    min-height: 42px !important;
}

.editorFloatButtonGroup .runProgramBtn .ant-float-btn-icon {
    transform: scale(2.2) translate(0px, 0.25px) !important;
    transition: transform 0.3s ease-in-out !important;
    color: white !important;
}

/* background-color: rgb(37, 255, 37) !important; */

.runProgramBtn:hover .ant-float-btn-body {
    background-color: black !important;
}

.editorFloatButtonGroup > .runProgramBtn:hover .ant-float-btn-icon {
    color: rgb(37, 255, 37) !important;
}

.editorFloatButtonGroup > .runProgramBtn:active .ant-float-btn-icon {
    color: white !important;
    transition: color 0.1s ease-in-out !important;
}

.saveIndicatorContainer {
    left: 0.5rem;
    top: 0.5rem;
    position: absolute;
    display: flex;
}

.saveIndicatorText {
    color: white;
}

.noDataFoundMessage {
    font-style: italic;
    color: red;
    font-weight: 600;
}

.editorOverlayContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    background: var(--sidebar-bg-color);
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    padding-bottom: 0.5rem;
}

.overlayCenteringContainer {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
    cursor: auto;
}

.submitProblemBtn {
    position: absolute;
    width: min-content;
    height: fit-content;
    white-space: break-spaces;
    right: 0.5rem;
    bottom: 0.5rem;
}

.confirmProblemModalContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.confirmModalWarningContainer {
    display: flex;
    gap: 1rem;
}

.solidExclamationSymbolConfirm {
    font-size: 1.25rem;
}

.editorProblemTitle {
    font-weight: 600;
    color: black;
    margin-top: 0.5rem;
    text-align: center;
}

.bottomEditorButtons {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

/* ANTD GENERAL CONFIGURATION */

.ant-form-item {
    margin-bottom: 5px;
}

.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

@media (min-width: 1100px) {
    .submitProblemBtn {
        width: max-content;
    }
}

/* Media queries */
/* If screen size less than 1400px */
@media (max-width: 1475px) {
    .editorProblemTitle {
        font-size: 1.5rem;
    }
}

/* Less than 1275px */
@media (max-width: 1275px) {
    .editorProblemTitle {
        font-size: 1.25rem;
    }
}
