.dockMenuOverallContainer {
    width: 200px;
    background-color: var(--sidebar-bg-color);
    display: flex;
    height: calc(100vh - 3px); /* 3px is the border width */
    flex-direction: column;
    transition: all 0.3s;
    border-bottom: 3px solid black;
}

.dockMenuOverallContainer.sidebarCollapsed {
    transition: none;
    width: 0px;
    padding: 0;
    border: none;
}

.dockMenuOverallContainer > h1 {
    padding-left: 0.5rem;
    padding-top: 0.5rem;
}

.dockMenuElementList {
    margin-top: 1rem;
    overflow-y: auto;
}

.dockMenuElementList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.dockMenuElementList ul:not(.subCategoryList) {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.dockMenuElementList h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    padding: 0.5rem;
    color: #333;
}

.dockMenuElementList > li {
    margin-bottom: 1rem;
    border-top: 3px solid black;
}

.dockMenuElement {
    padding: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    max-height: 200px;
}

.dockMenuElement:hover {
    background-color: #333;
    color: white;
}

.dockMenuElement img {
    flex: 1;
    min-height: 0;
    width: fit-content;
    object-fit: contain;
    border-radius: 0.25rem;
}

.subCategoryList .subCategory {
    padding-left: 20px;
    margin-top: 5px;
}

.siderContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 fit-content !important;
    width: fit-content !important;
    min-width: 50px !important;
    max-width: none !important;
    border: 3px solid black;
}

.siderContainer.siderCollapsed {
    /* width: 80px !important; */
    max-width: 110px !important;
    flex: 0 0 110px !important;
}

.ant-layout-sider-children {
    flex: 1;
    min-height: 0;
}

.ant-layout-sider-trigger {
    max-width: 110px;
}

.siderTabs {
    height: 100%;
    transition: all 0.3s;
    width: fit-content;
    padding-bottom: 5rem;
}

.ant-tabs-tab-btn {
    color: white;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: limegreen !important;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background-color: limegreen;
}

.siderTabs.siderTabsCollapsed {
    width: 80px;
    margin-top: 3rem;
    /* padding-top: 3rem; */
    padding-bottom: 10rem;
}

.siderCollapseBtn,
.siderProblemsBtn,
.siderLogoutBtn {
    position: absolute !important;
    width: 110px !important;
    z-index: 2;
}

.siderProblemsBtn {
    bottom: 36px;
}

.topSiderButton {
    top: 0;
    right: 0;
}

.topSiderButton:not(.siderCollapseBtnCollapsed) {
    border-radius: 0;
    border-bottom-left-radius: 0.5rem;
}

.bottomSiderButton {
    bottom: 4.5rem;
    right: unset;
    left: 0;
}

.bottomSiderButton:not(.siderCollapseBtnCollapsed) {
    bottom: 0;
    right: 0;
    left: unset;
    border-radius: 0;
    border-top-left-radius: 0.5rem;
}

.bottomSiderButton.ajustForOverflow {
    right: 1.5rem;
}

.siderLogoutBtn {
    bottom: 0;
}

.ant-tabs-nav {
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.ant-tabs-tab:hover {
    background-color: limegreen;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: black !important;
}

.nodePreviewTitle {
    text-align: center;
}

.blockPopoverOverlay {
    border: 3px solid #608eff;
    box-shadow: 0 0 8px 1px #608eff;
    border-radius: 0.5rem;
}

.blockPopoverOverlay .ant-popover-inner {
    border-radius: 0.5rem;
}

.blockPopoverContent {
    max-width: 500px;
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    padding-top: 0.5rem;
}

.blockDescriptionContainer h3 {
    text-align: center;
}

.blockDescriptionText {
    font-style: italic;
}

.boldText {
    font-weight: bold;
}

.inputOutputContainer {
    display: flex;
    align-items: stretch;
}

.dividerContainer {
    display: flex;
    align-items: stretch;
}

.dividerContainer .verticalDivider {
    height: 100%;
}

.divider {
    margin: 0.5rem 0;
}

.inputsColumn,
.outputsColumn {
    flex: 1;
}

.emptyInputOutput {
    flex: none;
}

.inputsColumn {
    min-width: 100px;
}

.outputsColumn {
    min-width: 100px;
}

.inputOutputTitle {
    text-decoration: underline;
}

.siderContainer .siderTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding-left: 0;
}

.blockPreRequisites {
    list-style: square;
    list-style-position: inside;
}

/* media query min width 1200px */
@media (min-width: 1200px) {
    .dockMenuOverallContainer {
        width: 250px;
    }
    .siderContainer .siderTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
        padding-left: 24px;
    }
}

/* media query min width 1440px */
@media (min-width: 1440px) {
    .dockMenuOverallContainer {
        width: 300px;
    }
}

/* media query min width 1920px */
@media (min-width: 1900px) {
    .dockMenuOverallContainer {
        width: 350px;
    }
}
